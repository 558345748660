import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import brulocalisImage from './brulocalis.png';

export const BrulocalisImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return <Image src={brulocalisImage} alt={t('brulocalis')} h={10} {...rest} />;
};
