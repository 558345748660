import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS, Paragraph } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next'; // TODO: Translate

// TODO: Translate
export const LandingWhereToParkContent = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.where-to-park' });
  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      <AppHeading qa={'app-heading-main-title'} as={HEADING_STYLING_KEYS.H2}>
        {t('title')}
      </AppHeading>
      <Paragraph>{t('content')}</Paragraph>
    </Box>
  );
};
