import { extendTheme } from '@chakra-ui/react';
import { mergeDeep, theme as handyParkTheme } from '@codeer/handypark-web-common';

import { themeColors, themeComponents, themeSemanticTokens } from './';

/*
 * Documentation:
 * https://chakra-ui.com/docs/styled-system/customize-theme
 * */

const themeAdditions = {
  colors: themeColors,
  components: themeComponents,
  semanticTokens: themeSemanticTokens,
};

const mergedTheme = () => mergeDeep([handyParkTheme, themeAdditions]);

export const theme = () => extendTheme(mergedTheme());
