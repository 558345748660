import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import nextGenerationEuImage from './next-generation-eu.png';

export const NextGenerationEuImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return <Image src={nextGenerationEuImage} alt={t('next-generation-eu')} h={8} {...rest} />;
};
