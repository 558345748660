import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';

const LandingPage = lazy(() =>
  import('@features/landing/pages').then(module => ({
    default: module.LandingPage,
  })),
);

export const landingRoutes: () => IRouteConfig[] = () => [
  {
    path: '/',
    component: LandingPage,
  },

  {
    path: '*',
    redirect: '/',
  },
];
