import { Box, List } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS, Paragraph } from '@codeer/handypark-web-common';
import { NumberedListItem } from '@core';
import { LandingHowItWorksNumberedListItemContentContainer } from '@features/landing';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next'; // TODO: Translate

// TODO: Translate
export const LandingHowItWorksContent = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.how-it-works' });

  const steps = useMemo(() => [1, 2, 3], []);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={9}>
      <AppHeading qa={'app-heading-main-title'} as={HEADING_STYLING_KEYS.H2}>
        {t('title')}
      </AppHeading>
      <List spacing={6}>
        {steps.map(step => (
          <NumberedListItem key={step} number={step}>
            <LandingHowItWorksNumberedListItemContentContainer>
              <AppHeading as={HEADING_STYLING_KEYS.H3}>{t(`steps.step-${step}.title`)}</AppHeading>
              <Paragraph>{t(`steps.step-${step}.description`)}</Paragraph>
            </LandingHowItWorksNumberedListItemContentContainer>
          </NumberedListItem>
        ))}
      </List>
    </Box>
  );
};
