import { customAppComponents } from '@core/theme';

/*
 * Documentation:
 * - https://chakra-ui.com/docs/styled-system/component-style
 * - https://chakra-ui.com/docs/components > component > theming
 * */

export const themeComponents = {
  // Custom created components
  ...customAppComponents,
};
