import { Grid, GridItem } from '@chakra-ui/react';
import {
  APP_CONTAINER_VARIANTS,
  AppContainer,
  COLOR_SCHEMES,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import { LandingWhereToParkContent, LandingWhereToParkImage } from '@features/landing';

type TLandingWhereToParkProps = TAppContainerProps;

export const LandingWhereToPark = ({ ...rest }: TLandingWhereToParkProps) => {
  return (
    <AppContainer
      as={'section'}
      variant={APP_CONTAINER_VARIANTS.FULL_WIDTH}
      background={`${COLOR_SCHEMES.BODY}.background`}
      py={12}
      {...rest}
    >
      <Grid templateColumns='repeat(24, 1fr)' rowGap={8} py={{ md: 6 }}>
        <GridItem
          colStart={{ base: 1, md: 1 }}
          colEnd={{ base: 25, md: 13 }}
          display={'flex'}
          justifyContent={'center'}
        >
          <AppContainer
            ms={'auto'}
            me={0}
            display={'flex'}
            alignItems={'center'}
            justifyContent={{ base: 'center', lg: 'center' }}
            maxWidth={{ base: 'none', md: 'md' }}
          >
            <LandingWhereToParkImage maxHeight={{ base: '17rem', md: 'initial' }} />
          </AppContainer>
        </GridItem>
        <GridItem
          colStart={{ base: 1, md: 13 }}
          colEnd={{ base: 25, md: 25 }}
          order={{ base: -1, md: 2 }}
          display={'flex'}
          justifyContent={'center'}
        >
          <AppContainer
            ms={0}
            me={'auto'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            h={'full'}
            maxWidth={{ base: 'none', md: 'md' }}
          >
            <LandingWhereToParkContent />
          </AppContainer>
        </GridItem>
      </Grid>
    </AppContainer>
  );
};
