import { Icon } from '@chakra-ui/react';

import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppIconProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const Monitor = ({ qa = 'monitor-icon', ...rest }: TAppIconProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'icons' });
  const title = t('monitor');
  return (
    <Icon
      data-qa={qa}
      aria-label={title}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 21'
      {...rest}
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M17 0.300049H3C2.20435 0.300049 1.44129 0.616119 0.87868 1.17873C0.316071 1.74134 0 2.5044 0 3.30005V13.3C0 14.0957 0.316071 14.8588 0.87868 15.4214C1.44129 15.984 2.20435 16.3 3 16.3H5.64L5.06 17.3C4.88446 17.6041 4.79205 17.949 4.79205 18.3C4.79205 18.6511 4.88446 18.996 5.06 19.3C5.23705 19.6067 5.49235 19.8609 5.79982 20.0366C6.10729 20.2123 6.45589 20.3032 6.81 20.3H13.27C13.6207 20.2997 13.9652 20.2071 14.2688 20.0316C14.5725 19.8561 14.8246 19.6038 15 19.3C15.1755 18.996 15.268 18.6511 15.268 18.3C15.268 17.949 15.1755 17.6041 15 17.3L14.41 16.3H17C17.7956 16.3 18.5587 15.984 19.1213 15.4214C19.6839 14.8588 20 14.0957 20 13.3V3.30005C20 2.5044 19.6839 1.74134 19.1213 1.17873C18.5587 0.616119 17.7956 0.300049 17 0.300049ZM6.77 18.3L8 16.3H12L13.2 18.3H6.77ZM18 13.3C18 13.5653 17.8946 13.8196 17.7071 14.0072C17.5196 14.1947 17.2652 14.3 17 14.3H3C2.73478 14.3 2.48043 14.1947 2.29289 14.0072C2.10536 13.8196 2 13.5653 2 13.3V12.3H18V13.3ZM18 10.3H2V3.30005C2 3.03483 2.10536 2.78048 2.29289 2.59294C2.48043 2.40541 2.73478 2.30005 3 2.30005H17C17.2652 2.30005 17.5196 2.40541 17.7071 2.59294C17.8946 2.78048 18 3.03483 18 3.30005V10.3Z'
        fill='currentColor'
      />
    </Icon>
  );
};
