import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import antwerpenMobiliteitEnParkerenImage from './antwerpen-mobiliteit-en-parkeren.png';

export const AntwerpenMobiliteitEnParkerenImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <Image
      src={antwerpenMobiliteitEnParkerenImage}
      alt={t('antwerpen-mobiliteit-en-parkeren')}
      height={14}
      {...rest}
    />
  );
};
