import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import deVlaamseVeerkrachtImage from './de-vlaamse-veerkracht.png';

export const DeVlaamseVeerkrachtImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return <Image src={deVlaamseVeerkrachtImage} alt={t('de-vlaamse-veerkracht')} h={10} {...rest} />;
};
