import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  APP_CONTAINER_VARIANTS,
  AppContainer,
  AppHeading,
  COLOR_SCHEMES,
  HEADING_STYLING_KEYS,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import { LandingHowToUseBlocks } from '@features/landing';
import { useTranslation } from 'react-i18next';

type TLandingHowToUseProps = TAppContainerProps;

export const LandingHowToUse = ({ ...rest }: TLandingHowToUseProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.how-to-use' });
  return (
    <AppContainer
      as={'section'}
      variant={APP_CONTAINER_VARIANTS.FULL_WIDTH}
      background={`${COLOR_SCHEMES.PRIMARY}.background`}
      py={12}
      {...rest}
    >
      <AppContainer variant={APP_CONTAINER_VARIANTS.CONTENT_MEDIUM} py={{ md: 6 }}>
        <AppHeading as={HEADING_STYLING_KEYS.H2}>{t('title')}</AppHeading>
        <LandingHowToUseBlocks mt={9} />
      </AppContainer>
    </AppContainer>
  );
};
