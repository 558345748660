import { useColorMode } from '@chakra-ui/react';
import { COLOR_SCHEMES, isLightMode } from '@codeer/handypark-web-common';
import {
  LandingHero,
  LandingHowItWorks,
  LandingHowToUse,
  LandingPartners,
  LandingWhereToPark,
} from '@features/landing';

export const Landing = () => {
  const { colorMode } = useColorMode();

  return (
    <>
      {/*
       * First section needs to be pushed up to be behind the header
       */}
      <LandingHero pt={16} mt={-16} />
      <LandingHowItWorks />
      <LandingHowToUse />
      <LandingWhereToPark />
      {/*
       * Last section needs to have a different color as the footer, but only in dark mode
       */}
      <LandingPartners
        borderBottom={!isLightMode(colorMode) ? `2px` : 'none'}
        borderBottomColor={`${COLOR_SCHEMES.BODY}.background`}
      />
    </>
  );
};
