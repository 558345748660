import { Box, useColorMode } from '@chakra-ui/react';
import { COLOR_SCHEMES, isLightMode, TAppBoxProps } from '@codeer/handypark-web-common';

type TPartnerLogoProps = TAppBoxProps;

export const PartnerLogo = ({ qa = 'partner-logo', children, ...rest }: TPartnerLogoProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      data-qa={qa}
      background={
        // This will always be white since black in dark mode will be white
        isLightMode(colorMode) ? `${COLOR_SCHEMES.GENERAL}.white` : `${COLOR_SCHEMES.GENERAL}.black`
      }
      borderRadius={'md'}
      height={{ sm: '7.5rem' }}
      py={{ base: 4, sm: 0 }}
      w={'full'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      {children}
    </Box>
  );
};
