import {
  APP_CONTAINER_VARIANTS,
  AppContainer,
  COLOR_SCHEMES,
  TAppContainerProps,
} from '@codeer/handypark-web-common';
import {
  PartnersCollaborationWith,
  PartnersGrantFrom,
  PartnersMadePossibleBy,
} from '@features/landing';

type TLandingPartnersProps = TAppContainerProps;

export const LandingPartners = ({ ...rest }: TLandingPartnersProps) => {
  return (
    <AppContainer
      as={'section'}
      variant={APP_CONTAINER_VARIANTS.FULL_WIDTH}
      background={`${COLOR_SCHEMES.PRIMARY}.background`}
      py={12}
      {...rest}
    >
      <AppContainer variant={APP_CONTAINER_VARIANTS.CONTENT_MEDIUM}>
        <PartnersMadePossibleBy />
        <PartnersCollaborationWith />
        <PartnersGrantFrom />
      </AppContainer>
    </AppContainer>
  );
};
