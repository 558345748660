import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import {
  AntwerpenMobiliteitEnParkerenImage,
  PartnerLogo,
  PartnerLogosContainer,
  PartnersGroupContainer,
} from '@features/landing';
import { useTranslation } from 'react-i18next';

export const PartnersMadePossibleBy = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <PartnersGroupContainer qa={'partners-made-possible-by'}>
      <AppHeading as={HEADING_STYLING_KEYS.H2} textAlign={'center'}>
        {t('made-possible-by')}
      </AppHeading>
      <PartnerLogosContainer maxWidth={{ sm: 64 }} gridTemplateColumns={'repeat(1, 1fr)'}>
        <PartnerLogo maxWidth={{ sm: 48 }}>
          <AntwerpenMobiliteitEnParkerenImage />
        </PartnerLogo>
      </PartnerLogosContainer>
    </PartnersGroupContainer>
  );
};
