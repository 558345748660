import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import agentschapBinnenlandsBestuurImage from './agentschap-binnenlands-bestuur.png';

export const AgentschapBinnenlandsBestuurImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });
  return (
    <Image
      src={agentschapBinnenlandsBestuurImage}
      alt={t('agentschap-binnenlands-bestuur')}
      h={10}
      {...rest}
    />
  );
};
