import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';

type TPartnersGroupContainerProps = TAppBoxProps;

export const PartnersGroupContainer = ({
  qa = 'partners-group-container',
  children,
  ...rest
}: TPartnersGroupContainerProps) => {
  return (
    <Box
      data-qa={qa}
      py={6}
      px={{ sm: 6 }}
      display={'flex'}
      gap={{ base: 4, md: 8 }}
      flexDirection={'column'}
      alignItems={'center'}
      {...rest}
    >
      {children}
    </Box>
  );
};
