import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TLandingHowItWorksNumberedListItemContentContainerProps = {
  children?: ReactNode;
};

export const LandingHowItWorksNumberedListItemContentContainer = ({
  children,
}: TLandingHowItWorksNumberedListItemContentContainerProps) => {
  if (!children) {
    return <></>;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      {children}
    </Box>
  );
};
