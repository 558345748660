import { Image, ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import landingWhereToParkImage from './landing-where-to-park.png';

type Props = Qa & ImageProps;

export const LandingWhereToParkImage = ({ qa = 'landing-where-to-park-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  return (
    <Image
      data-qa={qa}
      src={landingWhereToParkImage}
      alt={t('onboarding.2')}
      maxHeight={'17rem'}
      w={'auto'}
      {...rest}
    />
  );
};
