import { Icon } from '@chakra-ui/react';

import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppIconProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const Mobile = ({ qa = 'mobile-icon', ...rest }: TAppIconProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'icons' });
  const title = t('mobile');
  return (
    <Icon
      data-qa={qa}
      aria-label={title}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 21'
      {...rest}
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M7.71 14.59L7.56 14.47C7.50426 14.4322 7.44372 14.4019 7.38 14.38L7.2 14.3C7.03782 14.2661 6.86976 14.2731 6.71092 14.3202C6.55208 14.3673 6.40744 14.4532 6.29 14.57C6.20167 14.6673 6.13064 14.7789 6.08 14.9C6.00432 15.0823 5.98423 15.2828 6.02226 15.4764C6.06028 15.67 6.15472 15.848 6.2937 15.988C6.43267 16.1281 6.60996 16.2239 6.80326 16.2634C6.99656 16.3029 7.19722 16.2843 7.38 16.21C7.4995 16.1521 7.61052 16.0781 7.71 15.99C7.84876 15.8494 7.94275 15.6709 7.98013 15.4769C8.0175 15.2829 7.99658 15.0822 7.92 14.9C7.87014 14.7844 7.79889 14.6792 7.71 14.59ZM11 0.300049H3C2.20435 0.300049 1.44129 0.616119 0.87868 1.17873C0.316071 1.74134 0 2.5044 0 3.30005V17.3C0 18.0957 0.316071 18.8588 0.87868 19.4214C1.44129 19.984 2.20435 20.3 3 20.3H11C11.7956 20.3 12.5587 19.984 13.1213 19.4214C13.6839 18.8588 14 18.0957 14 17.3V3.30005C14 2.5044 13.6839 1.74134 13.1213 1.17873C12.5587 0.616119 11.7956 0.300049 11 0.300049ZM12 17.3C12 17.5653 11.8946 17.8196 11.7071 18.0072C11.5196 18.1947 11.2652 18.3 11 18.3H3C2.73478 18.3 2.48043 18.1947 2.29289 18.0072C2.10536 17.8196 2 17.5653 2 17.3V3.30005C2 3.03483 2.10536 2.78048 2.29289 2.59294C2.48043 2.40541 2.73478 2.30005 3 2.30005H11C11.2652 2.30005 11.5196 2.40541 11.7071 2.59294C11.8946 2.78048 12 3.03483 12 3.30005V17.3Z'
        fill='currentColor'
      />
    </Icon>
  );
};
