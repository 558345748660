import { APP_COMPONENT_NAMES } from '@/core';
import { Box, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';

type TNumberedListItemCircleProps = TAppBoxProps & {
  number: number;
};

export const NumberedListItemCircle = ({ number, ...rest }: TNumberedListItemCircleProps) => {
  const styles = useMultiStyleConfig(APP_COMPONENT_NAMES.NUMBERED_LIST_ITEM);

  return (
    <Box data-qa={'numbered-list-item-item-item-item-circle'} sx={styles.circle} {...rest}>
      <Text textStyle={'subtitle'} sx={styles.circleNumber}>
        {number}
      </Text>
    </Box>
  );
};
