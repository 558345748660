import { Mobile } from '@/assets/icons';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppBoxProps } from '@codeer/handypark-web-common';
import { LandingHowToUseBlock } from '@features/landing';
import { useTranslation } from 'react-i18next';

export const LandingHowToUseBlockByApp = ({ ...rest }: TAppBoxProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.how-to-use.by-app' });

  return (
    <LandingHowToUseBlock
      icon={<Mobile />}
      title={t('title')}
      content={t('content')}
      linkText={t('link.text')}
      href={t('link.href')}
      {...rest}
    />
  );
};
