import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';
import {
  LandingHowToUseBlockByApp,
  LandingHowToUseBlockBySms,
  LandingHowToUseBlockByWeb,
} from '@features/landing';

type TLandingHowToUseBlocksProps = TAppBoxProps;

export const LandingHowToUseBlocks = ({ ...rest }: TLandingHowToUseBlocksProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={{ base: 'column', md: 'row' }}
      gap={{ base: 8, md: 12 }}
      justifyContent={'space-between'}
      sx={{ '> *': { flexGrow: 1 } }}
      {...rest}
    >
      <LandingHowToUseBlockByApp />
      <LandingHowToUseBlockByWeb />
      <LandingHowToUseBlockBySms />
    </Box>
  );
};
