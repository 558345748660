// import '@codeer/handypark-common/fonts/Figtree/figtree.font.scss';

import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { TGeneralContextProviderProps } from '@codeer/handypark-web-common';
import { HeadTags, theme } from '@core';

import { FC } from 'react';

export const MainProvider: FC<TGeneralContextProviderProps> = ({ children }) => {
  console.log({ theme: theme() });

  return (
    <ChakraProvider theme={theme()}>
      <CSSReset />
      <HeadTags />
      {children}
    </ChakraProvider>
  );
};
