import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import unionDesVillesEtCommunesDeWallonieImage from './union-des-villes-et-communes-de-wallonie.jpg';

export const UnionDesVillesEtCommunesDeWallonieImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <Image
      src={unionDesVillesEtCommunesDeWallonieImage}
      alt={t('union-des-villes-et-communes-de-wallonie')}
      h={{ base: 12, md: 10 }}
      {...rest}
    />
  );
};
