import { Box, ListItem } from '@chakra-ui/react';
import { NumberedListItemCircle } from '@core';
import { ReactNode } from 'react';

type TNumberedListItemProps = {
  number: number;
  children?: ReactNode;
};

export const NumberedListItem = ({ number, children }: TNumberedListItemProps) => {
  return (
    <ListItem display={'flex'} gap={4}>
      <NumberedListItemCircle number={number} />
      {children && <Box>{children}</Box>}
    </ListItem>
  );
};
