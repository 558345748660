import { Box } from '@chakra-ui/react';
import { TAppBoxProps } from '@codeer/handypark-web-common';

type TPartnerLogosContainerProps = TAppBoxProps;

export const PartnerLogosContainer = ({
  qa = 'partner-logos-container',
  children,
  ...rest
}: TPartnerLogosContainerProps) => {
  return (
    <Box
      data-qa={qa}
      display={'grid'}
      gap={{ base: 4, md: 8 }}
      gridTemplateColumns={'repeat(3, 1fr)'}
      placeItems={'center'}
      w={'full'}
      maxWidth={'lg'}
      {...rest}
    >
      {children}
    </Box>
  );
};
