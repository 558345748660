import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import {
  BrulocalisImage,
  PartnerLogo,
  PartnerLogosContainer,
  PartnersGroupContainer,
  UnionDesVillesEtCommunesDeWallonieImage,
  VvsgImage,
} from '@features/landing';
import { useTranslation } from 'react-i18next';

export const PartnersCollaborationWith = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <PartnersGroupContainer qa={'partners-collaboration-with'}>
      <AppHeading as={HEADING_STYLING_KEYS.H3} textAlign={'center'}>
        {t('collaboration-with')}
      </AppHeading>
      <PartnerLogosContainer
        gridTemplateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
      >
        <PartnerLogo>
          <VvsgImage />
        </PartnerLogo>
        <PartnerLogo>
          <BrulocalisImage />
        </PartnerLogo>
        <PartnerLogo>
          <UnionDesVillesEtCommunesDeWallonieImage />
        </PartnerLogo>
      </PartnerLogosContainer>
    </PartnersGroupContainer>
  );
};
