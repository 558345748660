import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import vlaanderenIsLokaalBestuurImage from './vlaanderen-is-lokaal-bestuur.png';

export const VlaanderenIsLokaalBestuurImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <Image
      src={vlaanderenIsLokaalBestuurImage}
      alt={t('vlaanderen-is-lokaal-bestuur')}
      h={14}
      {...rest}
    />
  );
};
