import { Image, ImageProps } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import vvsgImage from './vvsg.png';

export const VvsgImage = ({ ...rest }: ImageProps) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES, { keyPrefix: 'onboarding.partners' });

  return <Image src={vvsgImage} alt={t('vvsg')} h={10} {...rest} />;
};
