import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppHeading, HEADING_STYLING_KEYS } from '@codeer/handypark-web-common';
import {
  AgentschapBinnenlandsBestuurImage,
  DeVlaamseVeerkrachtImage,
  NextGenerationEuImage,
  PartnerLogo,
  PartnerLogosContainer,
  PartnersGroupContainer,
  VlaanderenIsLokaalBestuurImage,
} from '@features/landing';
import { useTranslation } from 'react-i18next';

export const PartnersGrantFrom = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, { keyPrefix: 'onboarding.partners' });

  return (
    <PartnersGroupContainer qa={'partners-grant-from'}>
      <AppHeading as={HEADING_STYLING_KEYS.H3} textAlign={'center'}>
        {t('grant-from')}
      </AppHeading>
      <PartnerLogosContainer gridTemplateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
        <PartnerLogo>
          <VlaanderenIsLokaalBestuurImage />
        </PartnerLogo>
        <PartnerLogo>
          <AgentschapBinnenlandsBestuurImage />
        </PartnerLogo>
        <PartnerLogo>
          <DeVlaamseVeerkrachtImage />
        </PartnerLogo>
        <PartnerLogo>
          <NextGenerationEuImage />
        </PartnerLogo>
      </PartnerLogosContainer>
    </PartnersGroupContainer>
  );
};
