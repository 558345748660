import { Image, ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import landingHowItWorksImage from './landing-how-it-works.png';

type Props = Qa & ImageProps;

export const LandingHowItWorksImage = ({ qa = 'landing-how-it-works-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  return (
    <Image
      data-qa={qa}
      src={landingHowItWorksImage}
      alt={t('onboarding.3')}
      maxHeight={'17rem'}
      w={'auto'}
      {...rest}
    />
  );
};
