import { Image, ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import landingHeroImage from './landing-hero.png';

type Props = Qa & ImageProps;

export const LandingHeroImage = ({ qa = 'landing-hero-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  return <Image data-qa={qa} src={landingHeroImage} alt={t('onboarding.1')} w={'auto'} {...rest} />;
};
